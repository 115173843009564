import React from 'react';
import { Section, CardWork } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.scss';

const sortBy = ['employment', 'property', 'retail', 'branding', 'tv-and-video', 'digital', 'internal-communication'];

const ListCollection = () => {
  const content = useStaticQuery(graphql`
    query ProjectCategories {
      allPrismicProjectCategory {
        nodes {
          uid
          data {
            title {
              text
            }
            thumbnail {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2028) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            video {
              url
            }
          }
        }
      }
    }
  `);

  const items = content?.allPrismicProjectCategory?.nodes;

  items.sort((a, b) => {
    const uidA = a?.uid;
    const uidB = b?.uid;
    return sortBy.indexOf(uidA) - sortBy.indexOf(uidB);
  });

  return (
    <Section className="list-collection">
      <div className="list-items">
        {items.map((item) => {
          const { data, uid } = item;
          const { title, thumbnail, video } = data || {};
          return <CardWork key={uid} title={title} video={video} thumbnail={thumbnail} url={`/projects/${uid}`} />;
        })}
      </div>
    </Section>
  );
};

export default ListCollection;

import React from 'react';
import { Section, Carousel, Image, Heading } from 'components';
import './styles.scss';

const carouselResponsive = {
  2200: { items: 3, autoHeight: true },
  1600: { items: 3, autoHeight: true },
  1340: { items: 3, autoHeight: true },
  1260: { items: 3, autoHeight: true },
  1025: { items: 2, autoHeight: false },
  769: { items: 1, autoHeight: false },
  660: { items: 1, autoHeight: false },
  400: { items: 1, autoHeight: false },
  0: { items: 1, autoHeight: false },
};

const Testimonial = props => {
  const { data } = props;
  const { primary, items } = data;
  const { title } = primary;
  return (
    <Section className="testimonial">
      <Heading.Title title={title} />

      <Carousel responsive={carouselResponsive} paddingLeft="2rem" autoHeight>
        {items?.map((item, index) => {
          const { name, role, review, logo } = item;
          return (
            <div key={index} className="testimonial-item">
              <div className="testimonial-logo">
                <Image image={logo} />
              </div>
              <div className="testimonial-review" dangerouslySetInnerHTML={{ __html: review?.html }} />
              <div className="testimonial-author">
                <span className="testimonial-author-name">{name}</span>
                <span>{role}</span>
              </div>
            </div>
          );
        })}
      </Carousel>
    </Section>
  );
};

export default Testimonial;

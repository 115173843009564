import React from 'react';
import { classNames } from 'helpers';
import { Section, Wrapper, Icon, Link, Image, Heading } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.scss';

const Team = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, subtitle, description } = primary;
  const content = useStaticQuery(graphql`
    query ListMember {
      allPrismicSettingsBodyTeamMember(sort: { order: ASC, fields: primary___sort_order }) {
        nodes {
          primary {
            name {
              text
            }
            role {
              text
            }
            photo {
              alt
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            illustration {
              url
            }
          }
        }
      }
    }
  `);

  const members = content?.allPrismicSettingsBodyTeamMember?.nodes || [];
  return (
    <Section className="team">
      <Heading title={title} subtitle={subtitle} description={description} />
      <div className="team-members">
        {members?.map((member) => {
          const { name, email, role, photo, illustration } = member?.primary || {};
          return (
            <div key={email} className="team-member">
              <div className="team-image">
                <Image className="team-image-photo" image={photo} />
                <Image className="team-image-gif" image={illustration} />
              </div>
              <div className="team-name">{name?.text}</div>
              <div className="team-role">{role?.text}</div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default Team;

import React from 'react';
import FooterLinks from '../FooterLinks';
import './styles.scss';

const FooterGroup = props => {
  const { label, items } = props;
  return (
    <div className="footer-group">
      <span className="footer-group-label">{label?.text}</span>
      <FooterLinks items={items} />
    </div>
  );
};

export default FooterGroup;

import React from 'react';
import { Section, Wrapper, Carousel } from 'components';
import './styles.scss';

const Banner = props => {
  const { data } = props;
  const { primary, items } = data;
  const { title, subtitle, description } = primary;
  return (
    <Section className="banner" background>
      <div className="banner-container">
        <div className="banner-heading">
          <h4>{title?.text}</h4>
          <h2 className="banner-subtitle">{subtitle?.text}</h2>
          <div className="banner-separator" />
        </div>
        <div className="banner-content">
          <div dangerouslySetInnerHTML={{ __html: description?.html }} />
        </div>
      </div>
    </Section>
  );
};

export default Banner;

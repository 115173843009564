import React from 'react';
import { classNames } from 'helpers';
import { Image } from 'components';
import './styles.scss';

const Mosaic = props => {
  const { items } = props;
  const single = items.length === 1;
  const classes = classNames('images-mosaic', { single });

  return (
    <div className={classes}>
      <div className="images-items">
        {items.map(({ image }, index) => (
          <div key={index} className="images-item">
            <Image image={image} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Mosaic;

import React from 'react';
import { Link, Image } from 'components';
import './styles.scss';

const Card = props => {
  const { title, thumbnail, category, index, description, date, url } = props;
  return (
    <Link className="card-article" to={`${url}`}>
      <div className="card-media">
        <div className="card-image">
          <Image image={thumbnail} />
        </div>
      </div>
      <div className="card-content">
        <div className="card-content-wrapper">
          <div className="card-heading">
            <div className="card-category">{category}</div>
            <div className="card-date">{date}</div>
          </div>
          <div className="card-title">{title?.text}</div>
          {index === 0 && <div className="card-description" dangerouslySetInnerHTML={{ __html: description?.html }} />}
        </div>
      </div>
    </Link>
  );
};

export default Card;

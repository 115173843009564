import { useState, useEffect } from 'react';

export const useOnScreen = (ref, initialState, trigger) => {
  const [onScreen, setOnScreen] = useState(initialState);
  const rootMargin = '0px';

  useEffect(() => {
    const refCurrent = ref?.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!onScreen) {
          setOnScreen(entry.isIntersecting);
        }
      },
      { rootMargin }
    );
    if (refCurrent) observer.observe(refCurrent);
    return () => {
      if (refCurrent) observer.unobserve(refCurrent);
    };
  }, [onScreen, ref, trigger]);

  return onScreen;
};

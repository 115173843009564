import React from 'react';
import { Section, Link, Heading, CardArticle } from 'components';
import './styles.scss';

const FeaturedArticles = props => {
  const { data } = props;
  const { primary, items } = data;
  const { title, link } = primary;
  return (
    <Section className="featured-articles" background>
      <Heading.Title title={title} />
      <div className="featured-items">
        {items.map((item, index) => {
          const { uid, url, document } = item?.article;
          if (!uid) return null;
          const { last_publication_date: date } = document;
          const { title: titleItem, thumbnail, category, description } = document?.data || {};
          return (
            <CardArticle
              key={uid}
              title={titleItem}
              thumbnail={thumbnail}
              category={category}
              description={description}
              date={date}
              url={url}
              index={index}
            />
          );
        })}
      </div>
      <Link variation="tertiary" type="rounded" size="wide" to="/insights">
        {link}
      </Link>
    </Section>
  );
};

export default FeaturedArticles;

import React from 'react';
import { classNames } from 'helpers';
import { Logo, Navigation, Wrapper, Link } from 'components';
import './styles.scss';

const FooterSitemap = (props) => {
  const { className, privacy, terms } = props;
  const classes = classNames('footer-sitemap', className);
  return (
    <div className={classes}>
      {privacy?.uid && (
        <div>
          <Link to={privacy?.url}>Privacy Policy</Link>
        </div>
      )}
      {terms?.uid && (
        <div>
          <Link to={terms?.url}>Terms & Conditions</Link>
        </div>
      )}
      <div>© Copyright {new Date().getFullYear()}</div>
    </div>
  );
};

const Footer = (props) => {
  const { bottomNavigation, email, address, socials, privacy, terms } = props;
  return (
    <footer className="footer">
      <Wrapper>
        <div className="footer-top">
          <div className="footer-logo-container">
            <Logo floating />
            <FooterSitemap privacy={privacy} terms={terms} className="footer-sitemap-top" />
          </div>

          <Navigation.Footer bottomNavigation={bottomNavigation} email={email} address={address} socials={socials} />
        </div>
        <FooterSitemap privacy={privacy} terms={terms} className="footer-sitemap-bottom" />
      </Wrapper>
    </footer>
  );
};

export default Footer;

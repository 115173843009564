import React from 'react';
import { classNames } from 'helpers';
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';
import './styles.scss';

const VideoPlayer = (props) => {
  const { className, src, placeholder } = props;
  const classes = classNames('video-player', className);

  return (
    <div className={classes}>
      <Player fluid src={src} poster={placeholder?.url}>
        <BigPlayButton position="center" className="video-player-button" />
      </Player>
    </div>
  );
};

export default VideoPlayer;

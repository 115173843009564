import React from 'react';
import { Section, Link, Heading, Card } from 'components';
import './styles.scss';

const FeaturedProjects = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, link } = primary || {};
  return (
    <Section className="featured-projects">
      <Heading.Title title={title} />
      <div className="featured-items">
        {items?.map((item) => {
          const { id, document, url } = item?.project;
          if (!id) return null;
          const { title: titleItem, thumbnail, category, logo, video } = document?.data || {};
          return (
            <Card
              key={id}
              url={url}
              title={titleItem}
              logo={logo}
              thumbnail={thumbnail}
              video={video}
              category={category?.uid}
            />
          );
        })}
      </div>
      {link && (
        <Link variation="tertiary" type="rounded" size="wide" to="/projects">
          {link}
        </Link>
      )}
    </Section>
  );
};

export default FeaturedProjects;

export const IconLibrary = {

  ArrowLeft: {
    path: 'M 19.980469 3.9902344 A 1.0001 1.0001 0 0 0 19.292969 4.2929688 L 9.2929688 14.292969 A 1.0001 1.0001 0 0 0 9.2929688 15.707031 L 19.292969 25.707031 A 1.0001 1.0001 0 1 0 20.707031 24.292969 L 11.414062 15 L 20.707031 5.7070312 A 1.0001 1.0001 0 0 0 19.980469 3.9902344 z',
  },

  ArrowRight: {
    path: 'M 9.9902344 3.9902344 A 1.0001 1.0001 0 0 0 9.2929688 5.7070312 L 18.585938 15 L 9.2929688 24.292969 A 1.0001 1.0001 0 1 0 10.707031 25.707031 L 20.707031 15.707031 A 1.0001 1.0001 0 0 0 20.707031 14.292969 L 10.707031 4.2929688 A 1.0001 1.0001 0 0 0 9.9902344 3.9902344 z',
  },

  ArrowTop: {
    path: 'M 14.9844 9 A 1.0001 1.0001 0 0 0 14.293 9.29297 L 4.29297 19.293 A 1.0001 1.0001 0 1 0 5.70703 20.707 L 15 11.4141 L 24.293 20.707 A 1.0001 1.0001 0 1 0 25.707 19.293 L 15.707 9.29297 A 1.0001 1.0001 0 0 0 14.9844 9 Z',
  },

  ArrowBottom: {
    path: 'M 24.990234 8.9863281 A 1.0001 1.0001 0 0 0 24.292969 9.2929688 L 15 18.585938 L 5.7070312 9.2929688 A 1.0001 1.0001 0 0 0 4.9902344 8.9902344 A 1.0001 1.0001 0 0 0 4.2929688 10.707031 L 14.292969 20.707031 A 1.0001 1.0001 0 0 0 15.707031 20.707031 L 25.707031 10.707031 A 1.0001 1.0001 0 0 0 24.990234 8.9863281 z',
  },

  Next: {
    path: 'M 22.990234 8.9902344 A 1.0001 1.0001 0 0 0 22.292969 10.707031 L 25.585938 14 L 2 14 A 1.0001 1.0001 0 1 0 2 16 L 25.585938 16 L 22.292969 19.292969 A 1.0001 1.0001 0 1 0 23.707031 20.707031 L 28.619141 15.794922 A 1.0001 1.0001 0 0 0 28.623047 14.207031 A 1.0001 1.0001 0 0 0 28.617188 14.203125 L 23.707031 9.2929688 A 1.0001 1.0001 0 0 0 22.990234 8.9902344 z',
  },

  Previous: {
    path: 'M 19.8125 13.09375 C 19.59375 13.132813 19.398438 13.242188 19.25 13.40625 L 8.34375 24.28125 L 7.65625 25 L 8.34375 25.71875 L 19.25 36.59375 C 19.492188 36.890625 19.878906 37.027344 20.253906 36.941406 C 20.625 36.855469 20.917969 36.5625 21.003906 36.191406 C 21.089844 35.816406 20.953125 35.429688 20.65625 35.1875 L 11.46875 26 L 41 26 C 41.359375 26.003906 41.695313 25.816406 41.878906 25.503906 C 42.058594 25.191406 42.058594 24.808594 41.878906 24.496094 C 41.695313 24.183594 41.359375 23.996094 41 24 L 11.46875 24 L 20.65625 14.8125 C 20.980469 14.511719 21.066406 14.035156 20.871094 13.640625 C 20.679688 13.242188 20.246094 13.023438 19.8125 13.09375 Z',
    width: 50,
    height: 50,
  },

  Delete: {
    path: 'M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-12h-12v12zm13-15h-3.5l-1-1h-5l-1 1h-3.5v2h14v-2z',
    width: 24,
    height: 24,
  },

  Dots: {
    path: 'M 5 12 C 3.343 12 2 13.343 2 15 C 2 16.657 3.343 18 5 18 C 6.657 18 8 16.657 8 15 C 8 13.343 6.657 12 5 12 z M 15 12 C 13.343 12 12 13.343 12 15 C 12 16.657 13.343 18 15 18 C 16.657 18 18 16.657 18 15 C 18 13.343 16.657 12 15 12 z M 25 12 C 23.343 12 22 13.343 22 15 C 22 16.657 23.343 18 25 18 C 26.657 18 28 16.657 28 15 C 28 13.343 26.657 12 25 12 z',
  },

  Close: {
    path: 'M19 6.41l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59z',
    width: 24,
    height: 24,
  },

  List: {
    path: 'M4 14h4v-4h-4v4zm0 5h4v-4h-4v4zm0-10h4v-4h-4v4zm5 5h12v-4h-12v4zm0 5h12v-4h-12v4zm0-14v4h12v-4h-12z',
    width: 24,
    height: 24,
  },

  Grid: {
    path: 'M 3 1 C 1.898438 1 1 1.898438 1 3 L 1 5 C 1 6.101563 1.898438 7 3 7 L 5 7 C 6.101563 7 7 6.101563 7 5 L 7 3 C 7 1.898438 6.101563 1 5 1 Z M 12 1 C 10.898438 1 10 1.898438 10 3 L 10 5 C 10 6.101563 10.898438 7 12 7 L 14 7 C 15.101563 7 16 6.101563 16 5 L 16 3 C 16 1.898438 15.101563 1 14 1 Z M 21 1 C 19.898438 1 19 1.898438 19 3 L 19 5 C 19 6.101563 19.898438 7 21 7 L 23 7 C 24.101563 7 25 6.101563 25 5 L 25 3 C 25 1.898438 24.101563 1 23 1 Z M 3 10 C 1.898438 10 1 10.898438 1 12 L 1 14 C 1 15.101563 1.898438 16 3 16 L 5 16 C 6.101563 16 7 15.101563 7 14 L 7 12 C 7 10.898438 6.101563 10 5 10 Z M 12 10 C 10.898438 10 10 10.898438 10 12 L 10 14 C 10 15.101563 10.898438 16 12 16 L 14 16 C 15.101563 16 16 15.101563 16 14 L 16 12 C 16 10.898438 15.101563 10 14 10 Z M 21 10 C 19.898438 10 19 10.898438 19 12 L 19 14 C 19 15.101563 19.898438 16 21 16 L 23 16 C 24.101563 16 25 15.101563 25 14 L 25 12 C 25 10.898438 24.101563 10 23 10 Z M 3 19 C 1.898438 19 1 19.898438 1 21 L 1 23 C 1 24.101563 1.898438 25 3 25 L 5 25 C 6.101563 25 7 24.101563 7 23 L 7 21 C 7 19.898438 6.101563 19 5 19 Z M 12 19 C 10.898438 19 10 19.898438 10 21 L 10 23 C 10 24.101563 10.898438 25 12 25 L 14 25 C 15.101563 25 16 24.101563 16 23 L 16 21 C 16 19.898438 15.101563 19 14 19 Z M 21 19 C 19.898438 19 19 19.898438 19 21 L 19 23 C 19 24.101563 19.898438 25 21 25 L 23 25 C 24.101563 25 25 24.101563 25 23 L 25 21 C 25 19.898438 24.101563 19 23 19 Z',
    width: 26,
    height: 26,
  },

  Check: {
    path: 'M9 16.17l-4.17-4.17-1.42 1.41 5.59 5.59 12-12-1.41-1.41z',
    width: 24,
    height: 24,
  },

  User: {
    path: 'M4 14h4v-4h-4v4zm0 5h4v-4h-4v4zm0-10h4v-4h-4v4zm5 5h12v-4h-12v4zm0 5h12v-4h-12v4zm0-14v4h12v-4h-12z',
    width: 24,
    height: 24,
  },

  Menu: {
    path: 'M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z',
  },

  Info: {
    path: 'M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z',
  },

  Bed: {
    path: 'M 5 4 C 3.895 4 3 4.895 3 6 L 3 13 L 5 13 L 5 11 C 5 10.448 5.448 10 6 10 L 13 10 C 13.552 10 14 10.448 14 11 L 14 13 L 16 13 L 16 11 C 16 10.448 16.448 10 17 10 L 24 10 C 24.552 10 25 10.448 25 11 L 25 13 L 27 13 L 27 6 C 27 4.895 26.105 4 25 4 L 5 4 z M 1.984375 13.986328 A 1.0001 1.0001 0 0 0 1 15 L 1 26 A 1.0001 1.0001 0 1 0 3 26 L 3 25 L 27 25 L 27 26 A 1.0001 1.0001 0 1 0 29 26 L 29 15 A 1.0001 1.0001 0 0 0 27.984375 13.986328 A 1.0001 1.0001 0 0 0 27 15 L 3 15 A 1.0001 1.0001 0 0 0 1.984375 13.986328 z',
  },

  Car: {
    path: 'M18.92 6.01c-.2-.59-.76-1.01-1.42-1.01h-11c-.66 0-1.21.42-1.42 1.01l-2.08 5.99v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zm-12.42 9.99c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm-12.5-5l1.5-4.5h11l1.5 4.5h-14z',
    width: 24,
    height: 24,
  },

  Bath: {
    path: 'M 8.5 0 C 6.026562 0 4 2.026562 4 4.5 L 4 11 L 2 11 A 1.0001 1.0001 0 1 0 2 13 L 4.8320312 13 A 1.0001 1.0001 0 0 0 5.1582031 13 L 28 13 A 1.0001 1.0001 0 1 0 28 11 L 6 11 L 6 4.5 C 6 3.107438 7.107438 2 8.5 2 C 9.7874305 2 10.818494 2.9500626 10.96875 4.1933594 C 9.8219828 4.6149525 9 5.7068815 9 7 L 15 7 C 15 5.6838676 14.147682 4.5781212 12.96875 4.1738281 C 12.804484 1.9830796 11.047436 0.25590586 8.8574219 0.072265625 A 1.0001 1.0001 0 0 0 8.5 0 z M 2 15 L 3.3125 21.058594 C 3.8115 23.358594 5.8462188 25 8.1992188 25 L 21.800781 25 C 24.153781 25 26.1895 23.358594 26.6875 21.058594 L 28 15 L 2 15 z',
  },

  Area: {
    path: 'M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4 5.1289062 L 4 11 A 1.0001 1.0001 0 1 0 6 11 L 6 7.4140625 L 10.792969 12.207031 A 1.0001 1.0001 0 1 0 12.207031 10.792969 L 7.4140625 6 L 11 6 A 1.0001 1.0001 0 1 0 11 4 L 5.1171875 4 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z M 24.980469 3.9902344 A 1.0001 1.0001 0 0 0 24.869141 4 L 19 4 A 1.0001 1.0001 0 1 0 19 6 L 22.585938 6 L 17.792969 10.792969 A 1.0001 1.0001 0 1 0 19.207031 12.207031 L 24 7.4140625 L 24 11 A 1.0001 1.0001 0 1 0 26 11 L 26 5.1269531 A 1.0001 1.0001 0 0 0 24.980469 3.9902344 z M 11.480469 17.490234 A 1.0001 1.0001 0 0 0 10.792969 17.792969 L 6 22.585938 L 6 19 A 1.0001 1.0001 0 0 0 4.984375 17.986328 A 1.0001 1.0001 0 0 0 4 19 L 4 24.847656 A 1.0001 1.0001 0 0 0 5.1523438 26 L 11 26 A 1.0001 1.0001 0 1 0 11 24 L 7.4140625 24 L 12.207031 19.207031 A 1.0001 1.0001 0 0 0 11.480469 17.490234 z M 18.490234 17.490234 A 1.0001 1.0001 0 0 0 17.792969 19.207031 L 22.585938 24 L 19 24 A 1.0001 1.0001 0 1 0 19 26 L 24.847656 26 A 1.0001 1.0001 0 0 0 26 24.847656 L 26 19 A 1.0001 1.0001 0 0 0 24.984375 17.986328 A 1.0001 1.0001 0 0 0 24 19 L 24 22.585938 L 19.207031 17.792969 A 1.0001 1.0001 0 0 0 18.490234 17.490234 z',
  },

  Twitter: {
    path: 'M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z',
  },

  Instagram: {
    width: 50,
    height: 50,
    path: 'M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z',
  },

  Pinterest: {
    path: 'M12,2C6.477,2,2,6.477,2,12c0,4.237,2.636,7.855,6.356,9.312c-0.087-0.791-0.167-2.005,0.035-2.868 c0.182-0.78,1.172-4.971,1.172-4.971s-0.299-0.599-0.299-1.484c0-1.391,0.806-2.428,1.809-2.428c0.853,0,1.265,0.641,1.265,1.408 c0,0.858-0.546,2.141-0.828,3.329c-0.236,0.996,0.499,1.807,1.481,1.807c1.777,0,3.143-1.874,3.143-4.579 c0-2.394-1.72-4.068-4.177-4.068c-2.845,0-4.515,2.134-4.515,4.34c0,0.859,0.331,1.781,0.744,2.282 c0.082,0.099,0.093,0.186,0.069,0.287c-0.076,0.316-0.244,0.995-0.277,1.134c-0.043,0.183-0.145,0.222-0.334,0.133 c-1.249-0.582-2.03-2.408-2.03-3.874c0-3.154,2.292-6.052,6.608-6.052c3.469,0,6.165,2.472,6.165,5.776 c0,3.447-2.173,6.22-5.189,6.22c-1.013,0-1.966-0.527-2.292-1.148c0,0-0.502,1.909-0.623,2.378 c-0.226,0.868-0.835,1.958-1.243,2.622C9.975,21.843,10.969,22,12,22c5.522,0,10-4.478,10-10S17.523,2,12,2z',
  },

  Like: {
    path: 'M 9.5449219 4 C 5.9299219 4 3 6.9299219 3 10.544922 C 3 16.837321 10.298975 22.849799 13.708984 25.527344 A 2 2 0 0 0 13.71875 25.535156 C 13.742115 25.5535 13.773881 25.579629 13.796875 25.597656 L 13.798828 25.595703 A 2 2 0 0 0 15 26 A 2 2 0 0 0 16.203125 25.595703 L 16.203125 25.597656 C 16.209855 25.59238 16.219801 25.585381 16.226562 25.580078 C 16.231704 25.576045 16.23898 25.570455 16.244141 25.566406 A 2 2 0 0 0 16.263672 25.548828 C 19.663109 22.880904 27 16.852336 27 10.544922 C 27 6.9299219 24.070078 4 20.455078 4 C 17.000078 4 15 7 15 7 C 15 7 12.999922 4 9.5449219 4 z',
  },

  View: {
    path: 'M 15 5 C 6.081703 5 0.32098813 14.21118 0.21679688 14.378906 A 1 1 0 0 0 0 15 A 1 1 0 0 0 0.16210938 15.544922 A 1 1 0 0 0 0.16601562 15.550781 C 0.18320928 15.586261 5.0188313 25 15 25 C 24.938822 25 29.767326 15.678741 29.826172 15.564453 A 1 1 0 0 0 29.837891 15.544922 A 1 1 0 0 0 30 15 A 1 1 0 0 0 29.785156 14.380859 A 1 1 0 0 0 29.783203 14.378906 C 29.679012 14.21118 23.918297 5 15 5 z M 15 8 C 18.866 8 22 11.134 22 15 C 22 18.866 18.866 22 15 22 C 11.134 22 8 18.866 8 15 C 8 11.134 11.134 8 15 8 z M 15 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 18 A 3 3 0 0 0 18 15 A 3 3 0 0 0 15 12 z',
  },

  Filter: {
    path: 'M 20 4 C 18.706841 4 17.604859 4.84267 17.1875 6 L 4 6 A 1.0001 1.0001 0 1 0 4 8 L 17.1875 8 C 17.604859 9.15733 18.706841 10 20 10 C 21.293159 10 22.395141 9.15733 22.8125 8 L 26 8 A 1.0001 1.0001 0 1 0 26 6 L 22.8125 6 C 22.395141 4.84267 21.293159 4 20 4 z M 20 6 C 20.54383 6 20.958426 6.4088115 20.988281 6.9433594 A 1.0001 1.0001 0 0 0 20.988281 7.0585938 C 20.957487 7.5921595 20.543157 8 20 8 C 19.45617 8 19.041574 7.5911885 19.011719 7.0566406 A 1.0001 1.0001 0 0 0 19.011719 6.9414062 C 19.042513 6.4078405 19.456843 6 20 6 z M 12 12 C 10.706841 12 9.6048586 12.84267 9.1875 14 L 4 14 A 1.0001 1.0001 0 1 0 4 16 L 9.1875 16 C 9.6048586 17.15733 10.706841 18 12 18 C 13.293159 18 14.395141 17.15733 14.8125 16 L 26 16 A 1.0001 1.0001 0 1 0 26 14 L 14.8125 14 C 14.395141 12.84267 13.293159 12 12 12 z M 12 14 C 12.54383 14 12.958426 14.408812 12.988281 14.943359 A 1.0001 1.0001 0 0 0 12.988281 15.058594 C 12.957487 15.592159 12.543157 16 12 16 C 11.45617 16 11.041574 15.591188 11.011719 15.056641 A 1.0001 1.0001 0 0 0 11.011719 14.941406 C 11.042513 14.407841 11.456843 14 12 14 z M 16 20 C 14.706841 20 13.604859 20.84267 13.1875 22 L 4 22 A 1.0001 1.0001 0 1 0 4 24 L 13.1875 24 C 13.604859 25.15733 14.706841 26 16 26 C 17.293159 26 18.395141 25.15733 18.8125 24 L 26 24 A 1.0001 1.0001 0 1 0 26 22 L 18.8125 22 C 18.395141 20.84267 17.293159 20 16 20 z M 16 22 C 16.54383 22 16.958426 22.408812 16.988281 22.943359 A 1.0001 1.0001 0 0 0 16.988281 23.058594 C 16.957487 23.592159 16.543157 24 16 24 C 15.45617 24 15.041574 23.591188 15.011719 23.056641 A 1.0001 1.0001 0 0 0 15.011719 22.941406 C 15.042513 22.407841 15.456843 22 16 22 z',
  },

  Add: {
    path: 'M 14.970703 2.9726562 A 2.0002 2.0002 0 0 0 13 5 L 13 13 L 5 13 A 2.0002 2.0002 0 1 0 5 17 L 13 17 L 13 25 A 2.0002 2.0002 0 1 0 17 25 L 17 17 L 25 17 A 2.0002 2.0002 0 1 0 25 13 L 17 13 L 17 5 A 2.0002 2.0002 0 0 0 14.970703 2.9726562 z',
  },

  Search: {
    path: 'M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z',
  },

  Category: {
    path: 'M 13 3 C 12.488 3 11.975938 3.1949375 11.585938 3.5859375 L 2.5859375 12.585938 C 1.8049375 13.366938 1.8049375 14.633063 2.5859375 15.414062 L 4.1484375 16.976562 C 4.3264375 16.301562 4.664875 15.677875 5.171875 15.171875 L 14.171875 6.171875 C 14.926875 5.415875 15.932 5 17 5 L 22.445312 5 C 21.752312 3.809 20.477 3 19 3 L 13 3 z M 17 7 C 16.488 7 15.975938 7.1949375 15.585938 7.5859375 L 6.5859375 16.585938 C 5.8049375 17.366937 5.8049375 18.633063 6.5859375 19.414062 L 14.585938 27.414062 C 15.366938 28.195062 16.633063 28.195063 17.414062 27.414062 L 26.414062 18.414062 C 26.805062 18.024063 27 17.512 27 17 L 27 11 C 27 8.791 25.209 7 23 7 L 17 7 z M 23 9 C 24.105 9 25 9.895 25 11 C 25 12.105 24.105 13 23 13 C 21.895 13 21 12.105 21 11 C 21 9.895 21.895 9 23 9 z',
  },

  VR: {
    path: 'M 6 4 C 4.894531 4 4 4.894531 4 6 L 20 6 C 20 4.894531 19.105469 4 18 4 Z M 4 7 C 2.894531 7 2 7.894531 2 9 L 2 18 C 2 19.105469 2.894531 20 4 20 L 8.90625 20 C 9.332031 20 9.703125 19.746094 9.84375 19.34375 L 10.65625 16.9375 C 10.851563 16.371094 11.402344 16 12 16 C 12.597656 16 13.148438 16.371094 13.34375 16.9375 L 14.15625 19.34375 C 14.296875 19.746094 14.667969 20 15.09375 20 L 20 20 C 21.105469 20 22 19.105469 22 18 L 22 9 C 22 7.894531 21.105469 7 20 7 Z M 6.5 11 C 7.882813 11 9 12.117188 9 13.5 C 9 14.882813 7.882813 16 6.5 16 C 5.117188 16 4 14.882813 4 13.5 C 4 12.117188 5.117188 11 6.5 11 Z M 17.5 11 C 18.882813 11 20 12.117188 20 13.5 C 20 14.882813 18.882813 16 17.5 16 C 16.117188 16 15 14.882813 15 13.5 C 15 12.117188 16.117188 11 17.5 11 Z M 12 17.03125 C 11.835938 17.03125 11.683594 17.117188 11.625 17.28125 L 11 19 L 13 19 L 12.375 17.28125 C 12.316406 17.117188 12.164063 17.03125 12 17.03125 Z ',
  },

  Minus: {
    path: 'M 0 15 25 15',
  },

  Plus: {
    path: 'M 0 15 30 15, M 15 0 15 30',
  },

  Edit: {
    path: 'M3 17.25v3.75h3.75l11.06-11.06-3.75-3.75-11.06 11.06zm17.71-10.21c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z',
    width: 24,
    height: 24,
  },

  Calendar: {
    path: 'M17 12h-5v5h5v-5zm-1-11v2h-8v-2h-2v2h-1c-1.11 0-1.99.9-1.99 2l-.01 14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2h-1v-2h-2zm3 18h-14v-11h14v11z',
    width: 24,
    height: 24,
  },

  Map: {
    path: 'M15,2C8.925,2,4,6.925,4,13c0,7.234,7.152,10.697,8.048,11.503c0.915,0.823,1.671,2.668,1.976,3.714 c0.148,0.508,0.564,0.765,0.976,0.776c0.413-0.012,0.828-0.269,0.976-0.776c0.305-1.046,1.061-2.89,1.976-3.714 C18.848,23.697,26,20.234,26,13C26,6.925,21.075,2,15,2z M15,16c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3S16.657,16,15,16z',
  },

  Architect: {
    width: 50,
    height: 50,
    path: 'M 24 0 C 23.4453 0 23 0.445313 23 1 L 23 3.4375 C 21.2422 4.21094 20 5.95703 20 8 C 20 10.7578 22.2422 13 25 13 C 27.7578 13 30 10.7578 30 8 C 30 5.95703 28.7578 4.21094 27 3.4375 L 27 1 C 27 0.445313 26.5547 0 26 0 Z M 19.0938 11.75 L 13.8125 26 L 7.96875 26 C 7.92578 25.9961 7.88672 25.9961 7.84375 26 C 7.29297 26.0352 6.87109 26.5117 6.90625 27.0625 C 6.94141 27.6133 7.41797 28.0352 7.96875 28 L 23 28 L 23 31 C 23 31.5547 23.4453 32 24 32 L 26 32 C 26.5547 32 27 31.5547 27 31 L 27 28 L 42 28 C 42.3594 28.0039 42.6953 27.8164 42.8789 27.5039 C 43.0586 27.1914 43.0586 26.8086 42.8789 26.4961 C 42.6953 26.1836 42.3594 25.9961 42 26 L 36.1875 26 L 30.9063 11.75 C 29.7773 13.5234 27.8867 14.7578 25.6875 14.9688 L 29.9375 26 L 27 26 L 27 23 C 27 22.4453 26.5547 22 26 22 L 24 22 C 23.4453 22 23 22.4453 23 23 L 23 26 L 20.0625 26 L 24.3125 14.9688 C 22.1133 14.7578 20.2227 13.5234 19.0938 11.75 Z M 12.375 29.9063 L 8.3125 40.8438 C 8.23828 41.0469 8.25 41.2891 8.3125 41.5 L 10 47.125 L 10 49 C 9.99609 49.3594 10.1836 49.6953 10.4961 49.8789 C 10.8086 50.0586 11.1914 50.0586 11.5039 49.8789 C 11.8164 49.6953 12.0039 49.3594 12 49 L 12 47.125 L 18.5938 29.9063 Z M 31.4063 29.9063 L 38 47.125 L 38 49 C 37.9961 49.3594 38.1836 49.6953 38.4961 49.8789 C 38.8086 50.0586 39.1914 50.0586 39.5039 49.8789 C 39.8164 49.6953 40.0039 49.3594 40 49 L 40 47.125 L 41.6875 41.5 C 41.75 41.2891 41.7656 41.0469 41.6875 40.8438 L 37.625 29.9063 Z',
  },

  Tooltip: {
    path: 'M15 12 L30 30 L0 30 Z',
  },

  Camera: {
    width: 50,
    height: 50,
    path: 'M 19.09375 5 C 18.097656 5 17.1875 5.53125 16.5625 6.46875 L 14.9375 9 L 6 9 C 3.242188 9 1 11.242188 1 14 L 1 38 C 1 40.757813 3.242188 43 6 43 L 44 43 C 46.757813 43 49 40.757813 49 38 L 49 14 C 49 11.242188 46.757813 9 44 9 L 34.9375 9 L 33.34375 6.4375 C 32.730469 5.519531 31.808594 5 30.8125 5 Z M 10 14 C 11.101563 14 12 14.898438 12 16 C 12 17.101563 11.101563 18 10 18 C 8.898438 18 8 17.101563 8 16 C 8 14.898438 8.898438 14 10 14 Z M 25 15 C 31.066406 15 36 19.933594 36 26 C 36 32.066406 31.066406 37 25 37 C 18.933594 37 14 32.066406 14 26 C 14 19.933594 18.933594 15 25 15 Z M 25 17 C 20.039063 17 16 21.039063 16 26 C 16 30.964844 20.039063 35 25 35 C 29.964844 35 34 30.964844 34 26 C 34 21.039063 29.964844 17 25 17 Z ',
  },

  Facebook: {
    width: 50,
    height: 50,
    path: 'M25 3C12.85 3 3 12.85 3 25c0 11.03 8.125 20.137 18.712 21.728V30.83H16.27v-5.783h5.443V21.2c0-6.37 3.104-9.168 8.4-9.168 2.536 0 3.877.188 4.512.274v5.048H31.01c-2.248 0-3.033 2.13-3.033 4.533v3.16h6.588l-.894 5.783h-5.694v15.944C38.716 45.318 47 36.137 47 25c0-12.15-9.85-22-22-22z',
  },

  Paint: {
    width: 50,
    height: 50,
    path: 'M 22.6875 3 C 22.4375 3 22.1992 3.11328 22 3.3125 L 12.6563 12.5625 L 12.625 12.5938 L 11.3125 13.875 C 13.7266 15.7461 19.25 17 26 17 C 27.6563 17 29 18.3438 29 20 C 29 21.6563 27.6563 23 26 23 C 24.6602 23 23.5391 22.1211 23.1563 20.9063 C 24.1055 20.957 25.0508 21 26 21 C 26.5547 21 27 20.5508 27 20 C 27 19.4492 26.5547 19 26 19 C 17.6953 19 8 17.0742 8 11.6875 C 8 9.20703 11.0508 7.30859 15.9688 6.46875 L 18.2813 4.15625 C 10.6211 4.82422 6 7.58984 6 11.6875 C 6 13.7344 6.92188 15.4063 8.46875 16.7188 L 3.1875 22 C 2.38672 22.8008 2 23.8984 2 25 C 2 26.1016 2.38672 27.1992 3.1875 28 L 19 43.8125 C 19.8008 44.6133 20.8008 45 22 45 C 23.1016 45 24.1992 44.6133 25 43.8125 L 43 25.7813 C 43.9648 26.8633 45 28.3008 45 29.6875 C 45 31.9219 44 36.0547 44 39 C 44 40.6445 45.3555 42 47 42 C 48.6445 42 50 40.6445 50 39 L 50 28.3125 C 50 23.8594 46.4492 20.2461 42.5 17.4688 C 38.7227 14.8086 34.4766 12.8945 31.9375 11.8438 L 23.4063 3.3125 C 23.207 3.11328 22.9375 3 22.6875 3 Z M 36.0313 15.9375 C 37.7773 16.8359 39.6523 17.8828 41.375 19.0938 C 45.1016 21.7148 48 24.9648 48 28.3125 L 48 39 C 48 39.5547 47.5547 40 47 40 C 46.4453 40 46 39.5547 46 39 C 46 36.5469 47 32.4531 47 29.6875 C 47 26.8359 44.8398 24.7148 43.7188 23.5938 L 43.6875 23.5938 Z',
  },

  Upload: {
    path: 'M19.35 10.04c-.68-3.45-3.71-6.04-7.35-6.04-2.89 0-5.4 1.64-6.65 4.04-3.01.32-5.35 2.87-5.35 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zm-5.35 2.96v4h-4v-4h-3l5-5 5 5h-3z',
    width: 24,
    height: 24,
  },

  Link: {
    path: 'M19 19h-14v-14h7v-2h-7c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zm-5-16v2h3.59l-9.83 9.83 1.41 1.41 9.83-9.83v3.59h2v-7h-7z',
    width: 24,
    height: 24,
  },

  Plane: {
    path: 'M2.01 21l20.99-9-20.99-9-.01 7 15 2-15 2z',
    width: 24,
    height: 24,
  },

  Image: {
    path: 'M21 19v-14c0-1.1-.9-2-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01 3.5-4.51 4.5 6h-14l3.5-4.5z',
    width: 24,
    height: 24,
  },

  Gallery: {
    path: 'M7 19h10v-15h-10v15zm-5-2h4v-11h-4v11zm16-11v11h4v-11h-4z',
    width: 24,
    height: 24,
  },

  Star: {
    path: 'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z',
    width: 24,
    height: 24,
  },

  Slideshow: {
    path: 'M10 8v8l5-4-5-4zm9-5h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14z',
    width: 24,
    height: 24,
  },

  Quote: {
    path: 'M6 17h3l2-4v-6h-6v6h3zm8 0h3l2-4v-6h-6v6h3z',
    width: 24,
    height: 24,
  },

  Text: {
    path: 'M5 17v2h14v-2h-14zm4.5-4.2h5l.9 2.2h2.1l-4.75-11h-1.5l-4.75 11h2.1l.9-2.2zm2.5-6.82l1.87 5.02h-3.74l1.87-5.02z',
    width: 24,
    height: 24,
  },

  Home: {
    path: 'M10 20v-6h4v6h5v-8h3l-10-9-10 9h3v8z',
    width: 24,
    height: 24,
  },

  Step: {
    path: 'M3 5h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm11 10h2v-10h-4v2h2v8zm7-14h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14z',
    width: 24,
    height: 24,
  },

  Verified: {
    path: 'M12 1l-9 4v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12v-6l-9-4zm-2 16l-4-4 1.41-1.41 2.59 2.58 6.59-6.59 1.41 1.42-8 8z',
    width: 24,
    height: 24,
  },

  Web: {
    path: 'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z',
    width: 24,
    height: 24,
  },

  News: {
    path: 'M20.33 4.67L18.67 3 17 4.67 15.33 3l-1.67 1.67L12 3l-1.67 1.67L8.67 3 7 4.67 5.33 3 3.67 4.67 2 3v16c0 1.1.9 2 2 2h16c1.1 0 1.99-.9 1.99-2L22 3l-1.67 1.67zM12 19H4v-7h8v7zm8 0h-7v-1h7v1zm0-2h-7v-1h7v1zm0-2h-7v-1h7v1zm0-2h-7v-1h7v1zm0-2H4V8h16v3z',
    width: 24,
    height: 24,
  },

  Work: {
    path: 'M20 6h-4v-2c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2h-4c-1.11 0-1.99.89-1.99 2l-.01 11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-11c0-1.11-.89-2-2-2zm-6 0h-4v-2h4v2z',
    width: 24,
    height: 24,
  },

  PhotoLibrary: {
    path: 'M22 16v-12c0-1.1-.9-2-2-2h-12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71 2.97-3.71 4 5h-12l3-4zm-9-6v14c0 1.1.9 2 2 2h14v-2h-14v-14h-2z',
    width: 24,
    height: 24,
  },
};

import React from 'react';
import { Section, Icon, Separator } from 'components';
import './styles.scss';

const Features = props => {
  const { data } = props;
  const { primary, items } = data;
  const { text } = primary;

  return (
    <Section className="features">
      <div className="features-container">
        <div className="features-text">
          <div className="features-text-container" dangerouslySetInnerHTML={{ __html: text?.html }} />
          <Separator />
        </div>
        <div className="features-icons">
          {items.map(item => {
            const { icon, text } = item;
            return (
              <div className="feature">
                <Icon title={icon} variation="quinary" size="md" />
                <div className="feature-title">{text}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default Features;

import React from 'react';
import { classNames } from 'helpers';
import './TextArea.scss';

const TextArea = props => {
  const { type = 'text', name, label, placeholder, className, onChange, onBlur, error } = props;

  const classes = classNames('field', 'textfield', className, { error });
  return (
    <div className={classes}>
      {label && (
        <label htmlFor={name} className="field-label">
          {label}
        </label>
      )}
      <textarea
        id={name}
        className="field-input"
        type={type}
        name={name}
        rows="3"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default TextArea;

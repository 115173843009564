import React from 'react';
import { Share } from 'components';
import './styles.scss';

const HeadingArticle = props => {
  const { category, url, title, author, date, description } = props;
  return (
    <div className="heading-article">
      <h4 className="heading-intro">
        <span className="heading-category">{category}</span>
        <span className="heading-date">{date}</span>
        <span className="heading-author">{author?.text}</span>
      </h4>
      <h1 className="heading-title">{title?.text}</h1>
      <Share url={url} />
    </div>
  );
};

export default HeadingArticle;

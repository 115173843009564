import React from 'react';
import { classNames } from 'helpers';
import { Link as GatsbyLink } from 'gatsby';
import './styles.scss';
import './stylesTypes.scss';
import './stylesVariation.scss';
import './stylesSizes.scss';

const Link = (props) => {
  const {
    children,
    className,
    to,
    title,
    type,
    size,
    variation,
    invert,
    target = '_blank',
    onClick = () => {},
  } = props;
  const isExternal = (to && to.indexOf('http') !== -1) || (to && to[0] === '#');
  const classes = classNames('link', size, type, variation, { invert }, className);
  if (isExternal) {
    const followUrls = ['https://www.designrush.com/agency/profile/'];
    const rel = followUrls.find((url) => to.indexOf(url) !== -1) ? null : 'nofollow noopener noreferrer';
    return (
      <a href={to} className={classes} title={title || null} target={target} onClick={onClick} rel={rel}>
        {children}
      </a>
    );
  }
  return (
    <GatsbyLink
      to={to}
      className={classes}
      title={title || null}
      onClick={onClick}
      partiallyActive
      activeClassName="active-link"
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;

import React, { useState, useEffect } from 'react';
import { Section } from 'components';

import './styles.scss';

const GoogleMapsEmbed = ({ data }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const {
    primary: { embed_link: link },
  } = data;

  const hasUrl = link && link.url;

  if (!mounted || !hasUrl) return null;

  return (
    <Section className="google-maps-embed">
      <div className="maps-container">
        <iframe title="google-maps-embed-iframe" src={link.url} height="100%" width="100%" style={{ border: 0 }} />
      </div>
    </Section>
  );
};

export default GoogleMapsEmbed;

import React from 'react';
import './styles.scss';
import './stylesTypes.scss';
import './stylesVariation.scss';
import './stylesSizes.scss';

const Button = props => {
  const { title, type = 'button', children, theme = 'primary', outline } = props;
  return (
    <button type={type} title={title || ''} className={`button ${theme} ${outline ? 'outline' : ''}`}>
      {children}
    </button>
  );
};

export default Button;

import React from 'react';
import { Section, Image as ImageComponent } from 'components';
import './styles.scss';

const Image = props => {
  const { data } = props;
  const image = data?.primary?.image;
  return (
    <Section className="section-image">
      <ImageComponent image={image} />
    </Section>
  );
};

export default Image;

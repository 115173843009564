module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Belong Creative', // Default Site Title used for SEO & PWA
  description: 'Attract the right people with magnetic branding and creative communications.', // Default Site Decription used for SEO
  siteName: 'Belong Creative', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/logos/belong_og.png', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Belong Creative', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Simon Druery', // Author for schemaORGJSONLD
  themeColor: '#003b5c',
  backgroundColor: '#13191E',

  twitter: '', // Twitter Username
  googleTagManagerId: '',
};

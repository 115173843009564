import React from 'react';
import { Section, Image } from 'components';
import './styles.scss';

const TwoColsImageText = props => {
  const { data } = props;
  const { primary } = data;
  const { content, image, image_left: imageLeft} = primary;
  return (
    <Section className={`two-cols-image-text ${imageLeft ? 'image-left' : ''}`}>
      <div className="two-cols-image-text-container">
        {content?.html && <div className="two-cols-image-text-content" dangerouslySetInnerHTML={{ __html: content?.html }} /> }
        <Image className="two-cols-image-text-image" image={image} />
      </div>
    </Section>
  );
};

export default TwoColsImageText;

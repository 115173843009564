import React from 'react';
import { Section, VideoPlayer } from 'components';
import './styles.scss';

const Video = ({ data }) => {
  const { primary } = data;
  const { video, placeholder } = primary;

  return (
    <Section className="section-video">
      <div className="section-video-container">
        <VideoPlayer src={video?.url} placeholder={placeholder} />
      </div>
    </Section>
  );
};

export default Video;

import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Section } from 'components';
import './styles.scss';

const CalendlyEmbed = ({ data }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const {
    primary: { embed_url: url },
  } = data;

  const hasUrl = url?.text;

  if (!mounted) return null;

  return (
    <Section className="calendly-embed">
      <div
        className="calendly-inline-widget"
        data-url={hasUrl || 'https://calendly.com/belongcreative/30-min-free-brand-consultation'}
      />
      <Helmet>
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async />
      </Helmet>
    </Section>
  );
};

export default CalendlyEmbed;

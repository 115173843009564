import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { classNames } from 'helpers';
import { validate } from './helpers/validate';
import TextField from './TextField/TextField';
import TextArea from './TextArea/TextArea';
import './styles.scss';

const Form = (props) => {
  const {
    name = 'contact',
    button = 'Submit',
    buttonStyle = 'tertiary',
    children,
    fields: fieldsInit,
    ebookFile,
  } = props;
  const [fields, setFields] = useState(fieldsInit);

  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  useEffect(
    () =>
      // Set submitting to false in clean up function
      () => {
        setSubmitting(false);
      },
    []
  );

  const encode = (formData) =>
    Object.keys(formData)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`)
      .join('&');

  const onSubmit = async (event) => {
    event.preventDefault();
    // get data
    const formData = new FormData(event.target);
    const data = {};
    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }
    // submission logic
    setSubmitting(true);
    try {
      const url = 'https://aeqni6fq2h.execute-api.ap-southeast-2.amazonaws.com/prod?format=json';
      const config = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encode({
          ...data,
          _to: 'd9bae0551ebbd2410d32c2c10a2e2853875429130e9b80b10087be', // Belong
          _bcc: 'c2bee1791c9add490e29c4881d392e5a', // WM
          _sender: 'Belong Creative',
          _formname: 'Form Submission from Belong Creative',
          _replyTo: data.email,
        }),
      };
      const response = await fetch(url, config);
      const queryString = ebookFile ? `?ebook=${ebookFile}` : '';
      if (response.ok) {
        return navigate(`/thank-you${queryString}`);
      }
      //
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Opps something went wrong, please try again');
      setSubmitting(false);
    }
  };

  // OnBlur Field handler
  const onBlur = (fieldName) => {
    const field = fields?.[fieldName];
    const fieldValue = field?.value;
    const fieldRequired = field?.required;
    const error = validate(fieldName, fieldValue, fieldRequired);
    setFields({ ...fields, [fieldName]: { ...field, value: fieldValue, error } });
  };

  // OnChange Field handler
  const onChange = (fieldName, fieldValue) => {
    const field = fields?.[fieldName];
    const fieldError = field?.error;
    const fieldRequired = field?.required;
    let error = null;
    if (fieldError) error = validate(fieldName, fieldValue, fieldRequired);
    setFields({ ...fields, [fieldName]: { ...field, value: fieldValue, error } });
  };

  return (
    <form className="form" name={name} onSubmit={onSubmit}>
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value={name} />
      {React.Children.map(children, (field) => {
        const { props: fieldProps } = field;
        const fieldName = fieldProps?.name;
        if (!fields?.[fieldName]) return null;
        const fieldError = fields?.[fieldName]?.error;
        return React.cloneElement(field, {
          ...fieldProps,
          error: fieldError,
          onBlur: () => onBlur(fieldName),
          onChange: (event) => onChange(fieldName, event?.target?.value),
        });
      })}
      <button type="submit" className={classNames('button rounded md', buttonStyle)} disabled={submitting}>
        {button}
      </button>
    </form>
  );
};

Form.TextArea = TextArea;
Form.TextField = TextField;

export default Form;

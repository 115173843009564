import React from 'react';
import { Link } from 'components';
import HeaderNavigationDropdown from '../HeaderNavigationDropdown/HeaderNavigationDropdown';
import './stylesLarge.scss';
import './stylesSmall.scss';

const HeaderNavigationItem = props => {
  const { menuLabel, menuLink, items } = props;
  return (
    <div className="header-navigation-item">
      {menuLink && menuLink.uid ? (
        <Link className="header-navigation-link" to={menuLink?.url}>
          {menuLabel?.text}
        </Link>
      ) : (
        <span className="header-navigation-link">{menuLabel?.text}</span>
      )}
      <HeaderNavigationDropdown items={items} />
    </div>
  );
};

export default HeaderNavigationItem;

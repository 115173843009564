import React, { useRef } from 'react';
import { Section, Icon, Link, Separator } from 'components';
import { useOnScreen } from 'helpers';
import { Spring, animated } from 'react-spring';
import './styles.scss';

// Add +1 everyday from 1st January 2020
const counter = (startCounter = 0) => {
  const dateStart = new Date('01 Jan 2020 00:00:00 GMT');
  const dateNow = new Date();
  const dateDiff = dateNow.getTime() - dateStart.getTime();
  const dateDiffDays = Math.trunc(dateDiff / (1000 * 3600 * 24));
  return dateDiffDays + startCounter;
};

const Statistics = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    title,
    subtitle,
    description,
    stats_icon,
    stats_number,
    stats_display,
    stats_title,
    stats_description,
  } = primary;

  const number = stats_display === 'Counter' ? counter(stats_number) : stats_number;
  const numberRef = useRef();
  const numberOnScreen = useOnScreen(numberRef);

  return (
    <Section className="statistics" background>
      <div className="statistics-container">
        <div className="statistics-content">
          <h4>{title.text}</h4>
          <h2>{subtitle?.text}</h2>
          <Separator />
          {description?.text && <p>{description.text}</p>}
          <div className="statistics-links">
            {items?.map((item) => {
              const { link, text } = item;
              if (!link?.uid) return null;
              return (
                <Link key={link?.uid} size="lg" variation="tertiary" type="rounded" to={link?.url}>
                  {text}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="statistics-diagram">
          <div className="statistics-diagram-container">
            <div className="statistics-diagram-content">
              <div className="statistics-diagram-wrapper">
                <Icon className="satistics-diagram-icon" variation="secondary" title={stats_icon} />
                <div className="satistics-diagram-number" ref={numberRef}>
                  {numberOnScreen && (
                    <Spring from={{ value: 0 }} to={{ value: number }}>
                      {({ value }) => <animated.div>{value.interpolate((val) => Math.floor(val))}</animated.div>}
                    </Spring>
                  )}
                </div>
                <div className="satistics-diagram-title">{stats_title}</div>
              </div>
            </div>
          </div>
          <div className="satistics-diagram-caption">{stats_description?.text}</div>
        </div>
      </div>
    </Section>
  );
};

export default Statistics;

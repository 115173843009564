import React from 'react';
import { linkResolver, classNames } from 'helpers';
import { Section, CardArticle, Wrapper, Link } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.scss';

const ListArticles = (props) => {
  const { selectedCategory = 'All' } = props;

  const content = useStaticQuery(graphql`
    query ListArticles {
      allPrismicArticle(sort: { fields: data___date_published, order: DESC }) {
        nodes {
          id
          uid
          type
          data {
            date_published(formatString: "D MMM")
            title {
              text
            }
            description {
              html
            }
            category
            article_category {
              uid
              document {
                ... on PrismicArticleCategory {
                  data {
                    category_name {
                      text
                    }
                  }
                }
              }
            }
            thumbnail {
              alt
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      allPrismicArticleCategory {
        nodes {
          uid
          id
          data {
            category_name {
              text
            }
          }
        }
      }
    }
  `);

  const articles = content?.allPrismicArticle?.nodes;
  const articleCategories = content?.allPrismicArticleCategory?.nodes;

  // Preferred order of categories
  const preferredOrder = [
    'BRAND STRATEGY',
    'BRAND DESIGN',
    'EMPLOYER BRANDING',
    'EMPLOYEE VALUE PROPOSITION',
    'CUSTOMER VALUE PROPOSITION',
    'RESEARCH',
    'CREATIVE',
    'ADVERTISING CAMPAIGNS',
    'INTERNAL COMMUNICATIONS',
    'BELONG',
  ];

  const sortedArticleCategories = articleCategories.sort((a, b) => {
    const nameA = a.data.category_name.text.toUpperCase();
    const nameB = b.data.category_name.text.toUpperCase();

    const indexA = preferredOrder.indexOf(nameA);
    const indexB = preferredOrder.indexOf(nameB);

    // Categories not in preferredOrder will have an index of -1, which we handle here
    if (indexA === -1 && indexB === -1) {
      return nameA.localeCompare(nameB);
    }

    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });

  const getFilteredArticles = (category) => {
    if (category === 'All') return articles;
    return articles.filter((item) => item?.data?.article_category?.document?.data?.category_name?.text === category);
  };

  const filteredArticles = getFilteredArticles(selectedCategory);

  return (
    <Section className="list-articles" fluid background>
      <div className="list-articles-content">
        <Wrapper className="section-wrapper">
          <div className="list-filters">
            <Link to="/insights/" className={classNames('list-filter', { active: selectedCategory === 'All' })}>
              All
            </Link>
            {sortedArticleCategories.map((option) => (
              <Link
                key={option.id}
                to={`/insights/${option.uid}`}
                className={classNames('list-filter', { active: selectedCategory === option.data?.category_name?.text })}
              >
                {option?.data?.category_name?.text}
              </Link>
            ))}
          </div>
          <div className="list-items">
            {filteredArticles.map((item, index) => {
              const { uid, type, data: dataItem } = item;
              const {
                title: titleItem,
                article_category: articleCategory,
                description,
                thumbnail,
                video,
                logo,
                date_published: date,
              } = dataItem;
              const categoryName = articleCategory?.document?.data?.category_name?.text;
              const categoryUid = articleCategory?.uid;
              const url = linkResolver(uid, type, categoryUid);
              return (
                <CardArticle
                  key={uid}
                  url={url}
                  title={titleItem}
                  description={description}
                  logo={logo}
                  thumbnail={thumbnail}
                  video={video}
                  category={categoryName}
                  index={index}
                  date={date}
                />
              );
            })}
          </div>
        </Wrapper>
      </div>
    </Section>
  );
};

export default ListArticles;

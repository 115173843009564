import React from 'react';
import { Section, Heading, Image, Icon, Form as FormComponent } from 'components';
import './styles.scss';

const fields = {
  firstname: { required: true, value: null },
  surname: { required: true, value: null },
  email: { required: true, value: null },
};

const Form = props => {
  const { data } = props;
  const { primary, items } = data;
  const { title, subtitle, body, form_title: formTitle, form_name: formName, image, ebook_file: ebookFile } = primary;
  return (
    <Section className="section-ebook" background>
      <div className="section-ebook-container">
        <Heading title={title} subtitle={subtitle} description={body} />
        <div className="section-ebook-form">
          <div className="section-ebook-form-title">{formTitle?.text}</div>
          <FormComponent
            name={ebookFile?.url ? formTitle?.text : formName}
            fields={fields}
            button="Download now"
            buttonStyle="secondary-fill"
            ebookFile={ebookFile?.url}
          >
            <FormComponent.TextField name="firstname" placeholder="First name" />
            <FormComponent.TextField name="surname" placeholder="Surname" />
            <FormComponent.TextField name="email" placeholder="Email" />
          </FormComponent>
        </div>
      </div>
      {image && items.length > 0 && (
        <div className="section-ebook-benefits">
          <div className="section-ebook-image">
            <Image image={image} />
          </div>
          <div className="section-ebook-items">
            {items.map(item => {
              const { icon, title: itemTitle, description } = item;
              return (
                <div className="section-ebook-item">
                  <div className="section-ebook-heading">
                    <Icon title={icon} size="lg" />
                    <div className="section-ebook-item-title">{itemTitle?.text}</div>
                  </div>
                  <div
                    className="section-ebook-item-description"
                    dangerouslySetInnerHTML={{ __html: description?.html }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Section>
  );
};

export default Form;

import React from 'react';
import HeadingTitle from './HeadingTitle/HeadingTitle';
import HeadingArticle from './HeadingArticle/HeadingArticle';
import './styles.scss';

const Heading = (props) => {
  const { title, subtitle, description } = props;
  return (
    <div className="heading">
      {title?.html && <div className="heading-title" dangerouslySetInnerHTML={{ __html: title?.html }} />}
      {subtitle?.html && <div className="heading-subtitle" dangerouslySetInnerHTML={{ __html: subtitle?.html }} />}
      <div className="heading-description" dangerouslySetInnerHTML={{ __html: description?.html }} />
    </div>
  );
};

Heading.Article = HeadingArticle;
Heading.Title = HeadingTitle;

export default Heading;

import React from 'react';
import { classNames } from 'helpers';
import './styles.scss';

const Separator = props => {
  const { className } = props;
  const classes = classNames('separator', className);
  return <div className={classes} />;
};

export default Separator;

import React, { useEffect, useState, useRef } from 'react';
import './styles.scss';

const Video = ({ id, src, type = 'video/mp4', placeholder, className, playOnOver = false }) => {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const video = ref?.current;
    video.addEventListener('loadeddata', () => setLoaded(true), false);
    setTimeout(() => {
      if (!loaded) setLoaded(true);
    }, 4000);
  }, []);

  const onMouseOverHandler = event => {
    const target = event?.target;
    if (playOnOver) {
      target.currentTime = 0;
      target.play();
    }
  };

  const onMouseOutHandler = event => {
    const target = event?.target;
    if (playOnOver) target.pause();
  };

  return (
    <div
      className={`video-bg-container ${className || ''}`}
      style={{ background: `url('${placeholder}') center no-repeat` }}
    >
      <video
        ref={ref}
        preload={!playOnOver ? playOnOver.toString() : null}
        autoPlay={!playOnOver}
        playsInline
        muted
        loop
        onFocus={onMouseOverHandler}
        onMouseOver={onMouseOverHandler}
        onMouseOut={onMouseOutHandler}
        className="video"
        id={id}
        style={{ opacity: loaded ? 1 : 0 }}
      >
        <source src={src} type={type} />
        Sorry, your browser doesn&#39;t support embedded videos.
      </video>
    </div>
  );
};

export default Video;

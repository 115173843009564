import React from 'react';
import { Link } from 'components';
import './stylesLarge.scss';
import './stylesSmall.scss';

const HeaderNavigationDropdown = props => {
  const { items } = props;
  if (!items || items.length === 0) return null;
  return (
    <div className="header-navigation-dropdown">
      <div className="header-navigation-dropdown-container">
        {items.map(item => {
          const { submenu_label: submenuLabel, submenu_link: submenuLink } = item;
          return (
            <Link key={submenuLink?.uid} className="submenu-link" to={submenuLink?.url}>
              {submenuLabel?.text}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default HeaderNavigationDropdown;

import React from 'react';
import { Link, Icon } from 'components';
import './styles.scss';

const iconLibrary = {
  Facebook: 'FB',
  LinkedIn: 'Linkedin',
  Vimeo: 'Vimeo',
  Instagram: 'Instagram',
};

const Social = (props) => {
  const { socials, variation = 'footer' } = props;
  if (!socials) return null;

  const styleSelector = {
    footer: {
      icon: 'whiteblue',
      iconOver: 'white',
    },
    contact: {
      icon: 'primary',
      iconOver: 'secondary',
    },
  }[variation];

  return (
    <div className="social">
      {socials.map((item) => {
        const { url, network } = item;
        const iconKey = iconLibrary[network];
        return (
          <Link key={network} className="social-item" to={url?.url}>
            <Icon className="social-icon" title={iconKey} size="md" variation={styleSelector?.icon} />
            <Icon className="social-icon-over" size="md" title={iconKey} variation={styleSelector?.iconOver} />
          </Link>
        );
      })}
    </div>
  );
};

export default Social;

import React from 'react';
import { Section, Heading as HeadingComponent } from 'components';
import './styles.scss';

const Heading = props => {
  const { data } = props;
  const { primary } = data;
  const { title, subtitle, description } = primary;
  return (
    <Section className="section-heading">
      <HeadingComponent type="h1" title={title} subtitle={subtitle} description={description} />
    </Section>
  );
};

export default Heading;

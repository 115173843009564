import React from 'react';
import { classNames } from 'helpers';
import './style.scss';

const Wrapper = props => {
  const { className, children } = props;
  const classes = classNames('wrapper', className);
  return <div className={classes}>{children}</div>;
};

export default Wrapper;

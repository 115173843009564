import React from 'react';
import { Section, Image, Share } from 'components';
import './styles.scss';

const Author = props => {
  const { context } = props;
  const author = context?.authorProfile?.primary;
  if (!author) return null;
  return (
    <Section className="author">
      <div className="author-container">
        <div className="author-photo">
          <div className="author-photo-container">
            <Image image={author?.photo} />
          </div>
        </div>
        <div className="author-info">
          <div className="author-name">{`Article by ${author?.name?.text}`}</div>
          <div className="author-description" dangerouslySetInnerHTML={{ __html: author?.description?.html }} />
        </div>
      </div>
      <div className="author-share">
        <Share url={context?.url} />
      </div>
    </Section>
  );
};

export default Author;

import React from 'react';
import './styles.scss';

const HeadingTitle = (props) => {
  const { title, useH1 } = props;

  //   if (!title) return null;
  //   if (title.html) return <div className="heading" dangerouslySetInnerHTML={{ __html: title.html }} />;

  return (
    <div className="heading">
      {useH1 ? <h1 className="heading-title">{title?.text}</h1> : <h2 className="heading-title">{title?.text}</h2>}
    </div>
  );
};

export default HeadingTitle;

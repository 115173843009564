import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'helpers';
import './styles.scss';
import './stylesLarge.scss';

const HeaderNavigationIcon = props => {
  const { className, toggleMenu } = props;
  const classes = classNames('header-navigation-icon', className);

  return (
    <div className={classes}>
      <div className="header-navigation-icon-container" role="button" onClick={() => toggleMenu(state => !state)}>
        <span className="MenuIconItem MenuIconTop" />
        <span className="MenuIconItem MenuIconBottom" />
      </div>
    </div>
  );
};

HeaderNavigationIcon.propTypes = {
  className: PropTypes.string,
};

HeaderNavigationIcon.defaultProps = {
  className: null,
};

export default HeaderNavigationIcon;

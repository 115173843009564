import React from 'react';
import { Link, Section, Video, Wrapper } from 'components';
import './styles.scss';

const HeroSection = props => {
  const { data } = props;
  const { primary, items } = data;
  const { title, description, video } = primary;

  return (
    <Section className="hero-video" fluid>
      <Video src={video?.url} className="hero-video-container" />
      <Wrapper className="hero-video-wrapper">
        <div className="hero-video-content">
          <h1>{title?.text}</h1>
          <div className="hero-video-description" dangerouslySetInnerHTML={{ __html: description?.text }} />
          <div className="hero-video-links">
            {items?.map(item => {
              const { linkText, url } = item;
              if (!url?.uid) return null;
              return (
                <Link key={url?.uid} size="lg" variation="secondary" type="rounded" invert to={url?.url}>
                  {linkText}
                </Link>
              );
            })}
          </div>
        </div>
      </Wrapper>
    </Section>
  );
};

export default HeroSection;

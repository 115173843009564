import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Section } from 'components';
import './styles.scss';

const TypformEmbed = ({ data }) => {
  let scriptFunc;
  const {
    primary: { form_id: formID },
  } = data;

  const [formId, setFormId] = useState(null);

  useEffect(() => {
    if (formID?.text) setFormId(formID?.text);
    const scriptFunction = () => {
      const d = document;
      const gi = d.getElementById;
      const ce = d.createElement;
      const gt = d.getElementsByTagName;
      const id = 'typef_orm';
      const b = 'https://embed.typeform.com/';
      if (!gi.call(d, id)) {
        const js = ce.call(d, 'script');
        js.id = id;
        js.src = `${b}embed.js`;
        const q = gt.call(d, 'script')[0];
        q.parentNode.insertBefore(js, q);
      }
    };
    scriptFunc = scriptFunction();
  }, []);

  return (
    <Section className="typeform-embed">
      <div
        className="typeform-widget"
        data-url={`https://form.typeform.com/to/${formId}?typeform-medium=embed-snippet`}
      />
      <Helmet>
        <script>{scriptFunc}</script>
      </Helmet>
    </Section>
  );
};

export default TypformEmbed;

import React, { useState } from 'react';
import { classNames } from 'helpers';
import { Logo, Wrapper } from 'components';
import HeaderNavigationIcon from './HeaderNavigationIcon/HeaderNavigationIcon';
import HeaderNavigationItem from './HeaderNavigationItem/HeaderNavigationItem';
import HeaderNavigationOverlay from './HeaderNavigationOverlay/HeaderNavigationOverlay';
import './stylesLarge.scss';
import './stylesSmall.scss';

const HeaderNavigation = props => {
  const { className, isHero, topNavigation } = props;
  const [showMenu, toggleMenu] = useState(false);
  const classes = classNames('header-navigation', { isHero }, { 'header-navigation-visible': showMenu }, className);

  return (
    <nav className={classes}>
      <Wrapper>
        <div className="header-navigation-container">
          <div className="header-navigation-logo">
            <Logo floating={isHero || showMenu} />
          </div>
          <HeaderNavigationIcon toggleMenu={toggleMenu} />
          <div className="header-navigation-items">
            {topNavigation.map(menuItem => {
              const { primary, items } = menuItem;
              const { menu_label: menuLabel, menu_link: menuLink } = primary;
              return (
                <HeaderNavigationItem key={menuLink?.uid} menuLabel={menuLabel} menuLink={menuLink} items={items} />
              );
            })}
          </div>
        </div>
      </Wrapper>
      <HeaderNavigationOverlay />
    </nav>
  );
};

export default HeaderNavigation;

import React from 'react';
import { classNames } from 'helpers';
import { Section, Image } from 'components';
import './styles.scss';

const Quote = props => {
  const { data } = props;
  const { primary } = data;
  const { quote, author, role, image, style } = primary;
  const classes = classNames('quote', style);
  return (
    <Section className={classes} background>
      <div className="quote-container">
        <div className="quote-content">
          <div className="quote-content-wrapper">
            <div className="quote-text" dangerouslySetInnerHTML={{ __html: quote?.text }} />
            {author?.text && <div className="quote-author">{author?.text}</div>}
            {author?.text && role && (
              <div className="quote-author-role" dangerouslySetInnerHTML={{ __html: role?.html }} />
            )}
          </div>
        </div>
        <div className="quote-media">
          <div className="quote-media-wrapper">
            <div className="quote-media-container">
              <Image image={image} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Quote;

import React from 'react';
import { Section, Heading } from 'components';
import './styles.scss';

const HeadingArticle = props => {
  const { data, context } = props;
  const { primary } = data;
  const { title, description } = primary;
  const { date, url, category, authorProfile } = context;
  return (
    <Section className="section-heading-article">
      <Heading.Article url={url} category={category} date={date} author={authorProfile?.primary?.name} title={title} />
      <div className="section-headin-article-description" dangerouslySetInnerHTML={{ __html: description?.html }} />
    </Section>
  );
};

export default HeadingArticle;

import React from 'react';
import { Section, Link, Image } from 'components';
import './styles.scss';

const FeaturedContent = (props) => {
  const { data } = props;
  const { items } = data;

  return (
    <Section className="featured-content" background>
      <div className="featured-items">
        {items.map((item) => {
          const { title, subtitle, description, link, link_url: linkUrl, thumbnail } = item;
          const linkText = link?.text;
          const url = linkUrl?.url;
          return (
            <div className="featured-item" key={title?.text}>
              <div className="featured-item-image">
                <Image image={thumbnail} />
              </div>
              <div className="featured-item-content">
                <div className="featured-item-content-container">
                  <div className="featured-item-title">{title?.text}</div>
                  <div className="featured-item-subtitle">{subtitle?.text}</div>
                  <div className="featured-item-description" dangerouslySetInnerHTML={{ __html: description?.html }} />
                  <div className="featured-item-link">
                    <Link variation="tertiary" type="rounded" size="md" to={url}>
                      {linkText}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default FeaturedContent;

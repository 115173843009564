import React from 'react';
import { classNames } from 'helpers';
import { Logo, Navigation, Wrapper } from 'components';
import './styles.scss';

const Header = props => {
  const { floating, topNavigation } = props;
  const classes = classNames('header', { floating });
  return (
    <header className={classes}>
      <Navigation.Header isHero={floating} topNavigation={topNavigation} />
    </header>
  );
};

export default Header;

import React from 'react';
import { classNames } from 'helpers';
import FooterSocials from './FooterSocials';
import FooterGroup from './FooterGroup';
import './styles.scss';

const FooterNavigation = props => {
  const { className, isHero, bottomNavigation, email, address, socials } = props;
  const classes = classNames('footer-navigation', { isHero }, className);
  return (
    <nav className={classes}>
      {bottomNavigation.map(menuItem => {
        const { primary, items } = menuItem;
        const { label } = primary;
        return <FooterGroup key={label?.text} label={label} items={items} />;
      })}
      <div className="footer-group footer-contact">
        <span className="footer-group-label">CONTACT</span>
        <div className="footer-links">
          <div className="footer-links-container">
            <a className="link footer-link email-link" href={`mailto:${email}`}>
              {email}
            </a>
            <a className="link footer-link address" href={`mailto:${email}`}>
              <div dangerouslySetInnerHTML={{ __html: address?.html }} />
            </a>
          </div>
        </div>
        <FooterSocials socials={socials} />
      </div>
    </nav>
  );
};

export default FooterNavigation;

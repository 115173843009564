import React from 'react';
import { Link, Image, Video } from 'components';
import { classNames } from 'helpers';
import './styles.scss';

const CardWork = props => {
  const { url, title, logo, thumbnail, video, category } = props;
  const classes = classNames('card-work', { 'card-with-video': video?.url });

  return (
    <Link className={classes} to={url}>
      <div className="card-media">
        <div className="card-image">
          <Image image={thumbnail} />
        </div>
        <div className="card-video">
          <Video src={video?.url} playOnOver />
        </div>
      </div>
      <div className="card-content">
        <span className="card-title">{title?.text}</span>
      </div>
    </Link>
  );
};

export default CardWork;

import React from 'react';
import { Icon, Link } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.scss';

const socials = [
  {
    name: 'LinkedIn',
    icon: 'Linkedin',
    url: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  },
  {
    name: 'Twitter',
    icon: 'Twitter',
    url: 'http://twitter.com/share?url=',
  },
  {
    name: 'Facebook',
    icon: 'FB',
    url: 'https://www.facebook.com/share.php?u=',
  },
  {
    name: 'Email',
    icon: 'Email',
    url: 'mailto:?subject=I wanted you to see this site&amp;body=Check out this site ',
  },
];

const Share = props => {
  const { url } = props;

  const content = useStaticQuery(graphql`
    query SiteInfo {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = content?.site?.siteMetadata?.siteUrl;
  return (
    <div className="share">
      <div className="share-container">
        <div className="share-text">Share this page</div>
        <div className="share-links">
          {socials.map(social => (
            <Link className="share-link" to={`${social.url}${siteUrl}${url}`}>
              <Icon title={social.icon} size="md" variation="midgrey" />
              <Icon className="share-link-over" size="md" title={social.icon} variation="darkgrey" />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Share;

import React from 'react';
import { Link } from 'components';
import logo from 'images/logo.svg';
import logoDark from 'images/logo_dark.svg';

import { siteName } from '../../../config/website';
import './styles.scss';

const Logo = props => {
  const { floating } = props;
  const logoType = floating ? logo : logoDark;
  return (
    <Link className="logo" to="/">
      <img src={logoType} alt={siteName} />
    </Link>
  );
};
export default Logo;

import React from 'react';
import { Section, Heading, Card } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.scss';

const ListProjects = (props) => {
  const { context } = props;
  const category = context?.category;

  const content = useStaticQuery(graphql`
    query ListProjects {
      allPrismicProject {
        nodes {
          id
          uid
          data {
            title {
              text
            }
            type
            name
            category {
              uid
              type
            }
            video {
              url
            }
            thumbnail {
              alt
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            logo {
              alt
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const itemsData = content?.allPrismicProject?.nodes;
  const items = itemsData?.filter((item) => item?.data?.category?.uid === category);

  return (
    <Section className="list-projects">
      <div className="list-items">
        {items.map((item) => {
          const { uid, data: dataItem } = item;
          const { title: titleItem, name, type, thumbnail, video, logo } = dataItem;
          return (
            <Card
              key={uid}
              url={`/projects/${category}/${uid}`}
              uid={uid}
              title={{ text: type }}
              logo={logo}
              thumbnail={thumbnail}
              video={video}
              category={titleItem?.text}
            />
          );
        })}
      </div>
    </Section>
  );
};

export default ListProjects;

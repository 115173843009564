import React from 'react';
import { Wrapper } from 'components';
import { classNames } from 'helpers';
import './styles.scss';

const Section = props => {
  const { className, fluid, children, background } = props;
  const classes = classNames('section', className, { 'section-background': background });
  return (
    <section className={classes}>
      {fluid ? children : <Wrapper className="section-wrapper">{children}</Wrapper>}
    </section>
  );
};

export default Section;

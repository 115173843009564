import React from 'react';
import { classNames } from 'helpers';
import { Section, Icon, Link, Heading } from 'components';
import './styles.scss';

const iconVariation = {
  Primary: {
    icon: 'secondary',
    link: 'secondary',
  },
  Secondary: {
    icon: 'primary',
    link: 'quaternary',
  },
  Tertiary: {
    icon: 'primary',
    link: 'primary',
  },
};

const QuickLinks = props => {
  const { data } = props;
  const { primary, items } = data;
  const { title } = primary;

  return (
    <Section className="quick-links">
      <Heading.Title title={title} />
      <div className="quick-links-items">
        {items?.map((item, index) => {
          const { icon, text, style = 'Primary', link, title, description } = item;
          return (
            <div key={index} className={classNames('quick-links-item', style)}>
              <div className="quick-links-icon">
                <Icon size="lg" variation={iconVariation[style]?.icon} title={icon} />
              </div>
              <div className="quick-links-content">
                <div className="quick-links-title">{title?.text}</div>
                <p className="quick-links-description">{description?.text}</p>
                <div className="quick-links-link">
                  {link?.url && (
                    <Link variation={iconVariation[style]?.link} type="rounded" size="md" to={link.url}>
                      {text}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default QuickLinks;

import React from 'react';
import { Section } from 'components';
import './styles.scss';

const Text = props => {
  const { data } = props;
  const { primary } = data;
  const { text } = primary;
  return (
    <Section className="section-text">
      <div className="text-container" dangerouslySetInnerHTML={{ __html: text?.html }} />
    </Section>
  );
};

export default Text;

import React from 'react';
import { Section } from 'components';
import Mosaic from './Mosaic/Mosaic';
import './styles.scss';

const Images = props => {
  const { data } = props;
  const items = data?.items;
  const display = data?.primary?.display;
  const displayImages = display === 'Slider' ? <Mosaic items={items} /> : <Mosaic items={items} />;
  return <Section className="section-image">{displayImages}</Section>;
};

export default Images;

import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'helpers';
import { Icon } from 'components';
import './CarouselArrow.scss';

export const CarouselArrow = props => {
  const { className, onClick } = props;
  const classes = classNames('carousel-arrow', className);

  const onClickHandler = () => {
    if (onClick) onClick();
  };

  return (
    <div className={classes}>
      <button type="button" onClick={onClickHandler}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" className="carousel-arrow-icon">
          <path d="M 11.414063 3.585938 L 8.585938 6.414063 L 15.171875 13 L 8.585938 19.585938 L 11.414063 22.414063 L 20.828125 13 Z"></path>
        </svg>
      </button>
    </div>
  );
};

CarouselArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CarouselArrow.defaultProps = {
  className: null,
  onClick: null,
};

import React from 'react';
import { Link, Icon, Social } from 'components';
import './styles.scss';

const FooterSocials = (props) => {
  const { socials } = props;
  if (!socials) return null;
  return (
    <div className="footer-socials">
      <div className="footer-group-label footer-socials-title">Follow us</div>
      <Social socials={socials} />
    </div>
  );
};

export default FooterSocials;
